import ExifReader from 'exifreader';

const hasBlobConstructor =
  typeof Blob !== 'undefined' &&
  (function checkBlobConstructor() {
    try {
      return Boolean(new Blob());
    } catch (error) {
      return false;
    }
  })();

const hasArrayBufferViewSupport =
  hasBlobConstructor &&
  typeof Uint8Array !== 'undefined' &&
  (function checkArrayBufferView() {
    try {
      return new Blob([new Uint8Array(100)]).size === 100;
    } catch (error) {
      return false;
    }
  })();

const hasToBlobSupport =
  typeof HTMLCanvasElement !== 'undefined'
    ? HTMLCanvasElement.prototype.toBlob
    : false;

const hasBlobSupport =
  hasToBlobSupport ||
  (typeof Uint8Array !== 'undefined' &&
    typeof ArrayBuffer !== 'undefined' &&
    typeof atob !== 'undefined');

const hasReaderSupport =
  typeof FileReader !== 'undefined' || typeof URL !== 'undefined';

const hasCanvasSupport = typeof HTMLCanvasElement !== 'undefined';

export default class ImageTools {
  constructor(device) {
    this.browserSupport = this.isSupportedByBrowser();
    this.device = device;
  }

  supportedImageType() {
    this.device.ios || this.device.macos ? 'image/jpeg' : 'image/webp';
  }

  isSupportedByBrowser() {
    hasCanvasSupport && hasBlobSupport && hasReaderSupport;
  }

  resize(file, maxDimensions, quality = 0.7) {
    return new Promise(resolve => {
      if (!this.browserSupport || !file.type.match(/image.*/))
        return resolve(file); // early exit - not supported
      if (file.type.match(/image\/gif/)) return resolve(file); // early exit - could be an animated gif
      const image = document.createElement('img');

      image.onload = () => {
        let width = image.width;
        let height = image.height;

        // Resize logic
        if (width >= height && width > maxDimensions.width) {
          height *= maxDimensions.width / width;
          width = maxDimensions.width;
        } else if (height > maxDimensions.height) {
          width *= maxDimensions.height / height;
          height = maxDimensions.height;
        }

        // Use FileReader to get EXIF data
        const reader = new FileReader();
        reader.onload = event => {
          const tags = ExifReader.load(event.target.result);

          // Get orientation if available, default to 1 (normal orientation)
          const orientation = tags.Orientation ? tags.Orientation.value : 1;

          // Drawing the image on canvas with the correct orientation
          const imageCanvas = this.drawImageToCanvas(
            image,
            orientation,
            0,
            0,
            width,
            height,
            'contain'
          );

          if (hasToBlobSupport) {
            imageCanvas.toBlob(
              blob => {
                blob.name =
                  file.name.replace(/\.[^/.]+$/, '') +
                  `.${this.supportedImageType().split('/')[1]}`;
                resolve(blob);
              },
              this.supportedImageType(),
              quality
            );
          } else {
            resolve(
              this.toBlob(imageCanvas, this.supportedImageType(), quality)
            );
          }
        };
        reader.readAsArrayBuffer(file); // Trigger the FileReader
      };

      this.loadImage(image, file);

      return true;
    });
  }

  crop(file, dimensions) {
    return new Promise(resolve => {
      if (!this.browserSupport || !file.type.match(/image.*/))
        return resolve(file); // early exit - not supported

      if (file.type.match(/image\/gif/)) return resolve(file); // early exit - could be an animated gif

      const image = document.createElement('img');

      image.onload = () => {
        if (dimensions.width > image.width && dimensions.height > image.height)
          return resolve(file); // early exit - no need to resize

        const width = Math.min(dimensions.width, image.width);
        const height = Math.min(dimensions.height, image.height);

        if (
          image.width > dimensions.width * 2 ||
          image.height > dimensions.height * 2
        ) {
          return this.resize(file, {
            width: dimensions.width * 2,
            height: dimensions.height * 2,
          }).then(zoomedOutImage => {
            this.crop(zoomedOutImage, { width, height }).then(resolve);
          });
        }

        // Use FileReader to get EXIF data
        const reader = new FileReader();
        reader.onload = event => {
          const tags = ExifReader.load(event.target.result);

          // Get orientation if available, default to 1 (normal orientation)
          const orientation = tags.Orientation ? tags.Orientation.value : 1;

          // Drawing the image on canvas with the correct orientation
          // const orientation = EXIF.getTag(image, "Orientation");
          const imageCanvas = this.drawImageToCanvas(
            image,
            orientation,
            0,
            0,
            width,
            height,
            'crop'
          );
          if (hasToBlobSupport)
            imageCanvas.toBlob(blob => resolve(blob), file.type);
          else resolve(this.toBlob(imageCanvas, file.type));
        };
        reader.readAsArrayBuffer(file); // Trigger the FileReader
      };

      this.loadImage(image, file);

      return true;
    });
  }

  drawImageToCanvas(
    img,
    orientation = 1,
    x = 0,
    y = 0,
    width = img.width,
    height = img.height,
    method = 'contain'
  ) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    ctx.save();
    switch (Number(orientation)) {
      // explained here: https://i.stack.imgur.com/6cJTP.gif
      case 1:
        break;

      case 2:
        ctx.translate(width, 0);
        ctx.scale(-1, 1);
        break;

      case 3:
        ctx.translate(width, height);
        ctx.rotate((180 / 180) * Math.PI); // 180/180 is 1? No shit, but how else will you know its need 180 rotation?
        break;

      case 4:
        ctx.translate(0, height);
        ctx.scale(1, -1);
        break;

      case 5:
        canvas.width = height;
        canvas.height = width;
        ctx.rotate((90 / 180) * Math.PI);
        ctx.scale(1, -1);
        break;

      case 6:
        canvas.width = height;
        canvas.height = width;
        ctx.rotate((90 / 180) * Math.PI);
        ctx.translate(0, -height);
        break;

      case 7:
        canvas.width = height;
        canvas.height = width;
        ctx.rotate((270 / 180) * Math.PI);
        ctx.translate(-width, height);
        ctx.scale(1, -1);
        break;

      case 8:
        canvas.width = height;
        canvas.height = width;
        ctx.translate(0, width);
        ctx.rotate((270 / 180) * Math.PI);
        break;

      default:
        break;
    }

    if (method === 'crop')
      ctx.drawImage(
        img,
        img.width / 2 - width / 2,
        img.height / 2 - height / 2,
        width,
        height,
        0,
        0,
        width,
        height
      );
    else ctx.drawImage(img, x, y, width, height);
    ctx.restore();

    return canvas;
  }

  toBlob(canvas, type, quality) {
    const dataURI = canvas.toDataURL(type, quality);
    const dataURIParts = dataURI.split(',');
    let byteString;
    if (dataURIParts[0].indexOf('base64') >= 0) {
      byteString = atob(dataURIParts[1]);
    } else {
      byteString = decodeURIComponent(dataURIParts[1]);
    }
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i += 1) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const mimeString = dataURIParts[0].split(':')[1].split(';')[0];
    let blob = null;

    if (hasBlobConstructor) {
      blob = new Blob([hasArrayBufferViewSupport ? intArray : arrayBuffer], {
        type: mimeString,
      });
    } else {
      // eslint-disable-next-line no-undef
      const bb = new BlobBuilder();
      bb.append(arrayBuffer);
      blob = bb.getBlob(mimeString);
    }

    return blob;
  }

  loadImage(image, file) {
    if (typeof URL === 'undefined') {
      const reader = new FileReader();
      reader.onload = event => {
        image.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      image.src = URL.createObjectURL(file);
    }
  }

  getVideoThumbnail(file) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      const url = URL.createObjectURL(file);

      video.setAttribute('src', url);
      video.load();

      video.addEventListener('loadedmetadata', () => {
        setTimeout(() => {
          video.currentTime = 0.0;
        }, 200);

        video.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          ctx.canvas.toBlob(
            blob => {
              resolve(blob);
            },
            this.supportedImageType(),
            0.75
          );
        });

        video.addEventListener('error', () => {
          reject(new Error('Error during video processing'));
        });
      });

      video.addEventListener('error', () => {
        reject(new Error('Error loading video metadata'));
      });
    });
  }
}
