import ExcelJS from 'exceljs';

async function xlsxToJson(file, sheetName) {
  try {
    const fileData = await readFileAsync(file);
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(fileData);
    const worksheet = workbook.getWorksheet(sheetName);
    const rowData = [];
    worksheet.eachRow(r => {
      rowData.push(r.values);
    });
    const [header, ...body] = rowData;
    const jsonResult = body.reduce((result, row) => {
      const obj = {};
      for (let i = 0; i < row.length; i++) {
        if (!header[i]) continue;
        obj[header[i]] = row[i];
        // handle "Color" column
        if (header[i] === 'Color') {
          try {
            //parse to Json
            const colorData = row[i] ? JSON.parse(row[i]) : null;
            if (
              colorData &&
              colorData.color &&
              Array.isArray(colorData.color)
            ) {
              obj[header[i]] = colorData.color;
            } else {
              obj[header[i]] = {}; // set default value if there is an error
            }
          } catch (error) {
            obj[header[i]] = {}; // set default value if there is an error
          }
        } else {
          obj[header[i]] = row[i];
        }
      }
      return [...result, obj];
    }, []);
    return jsonResult;
  } catch (error) {
    return [];
  }
}

async function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    var fileReader = new FileReader();
    fileReader.onload = function (event) {
      resolve(event.target.result);
    };
    fileReader.onerror = function () {
      reject();
    };
    fileReader.readAsArrayBuffer(file);
  });
}

export default xlsxToJson;
