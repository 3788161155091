import { CONTRACT_CHECKLIST } from './const';
import { toDisplayDateString } from './datetime';
import { getFullAddress } from './address';
import _ from 'lodash';
import moment from 'moment';

export const contractInfoDataCalculator = {
  'Roofing Logo URL': self => {
    return self.setting.logoUrl || '';
  },
  'Roofing Company Name UpperCase': self => {
    return (self.setting.companyName || '').toUpperCase();
  },
  'Roofing Company Name': self => {
    return self.setting.companyName || '';
  },
  'Roofing Company Email': self => {
    return self.setting.companyEmail || self.setting.companyWebsite || '';
  },
  'Roofing Company Phone': self => {
    return self.setting.phoneNumber || '';
  },

  //**------------------------- */
  'Project Name': self => {
    return self.card.title || '';
  },
  'Date of Loss': self => {
    return toDisplayDateString(self.card.dateOfLoss);
  },
  'Inspected by': self => {
    return self.card.inspectorName || '';
  },
  // eslint-disable-next-line no-unused-vars
  'Referred by': self => {
    return self.card.referralName || '';
  },

  /**------------------------ */
  'Owner Name': self => {
    return self.contactName || '';
  },
  'Owner Email': self => {
    return self.contactEmail || ''; // owner email
  },
  'Owner Phone': self => {
    return self.contactPhone || ''; // owner Phone
  },
  'Owner Address': self => {
    return (
      (self.card.projectAddress && getFullAddress(self.card.projectAddress)) ||
      ''
    ); // owner Address
  },
  'Owner City': self => {
    return (self.card.projectAddress && self.card.projectAddress.city) || ''; // owner Address
  },
  'Owner Zipcode': self => {
    return (self.card.projectAddress && self.card.projectAddress.zipcode) || ''; // owner Address
  },
  /**------------------------ */

  'Policy Number': self => {
    return self.card.policy || ''; // Policy #
  },
  'Claim Number': self => {
    return self.card.claim || ''; // Claim #
  },

  'Insurance Company Name': self => {
    return self.insuranceCompanyName || ''; // Insurance Co. name
  },
  'Insurance Company Phone': self => {
    return self.insuranceCompanyPhone || ''; // Insurance Co. Phone #
  },

  'Checkbox Remove Roof': self => {
    return `<input type="checkbox" ${
      self.isRemoveRoof ? 'checked' : ''
    } style="height: 18px; width: 18px; margin: 0px;  accent-color: #1a79a1; pointer-events: none;">`;
  },
  'Checkbox Replace Roof': self => {
    return `<input type="checkbox" ${
      self.isReplaceRoof ? 'checked' : ''
    } style="height: 18px; width: 18px; margin: 0px;  accent-color: #1a79a1; pointer-events: none;">`;
  },
};

export const contractCheckListDataCalculator = {
  'Contract Checklist': (self, isNew) => {
    let checklist = [];
    if (isNew) {
      checklist = CONTRACT_CHECKLIST;
    } else {
      checklist = self.contract ? self.contract.contractChecklist || [] : [];
    }
    let result = '';
    let counter = 1;
    for (const row of checklist.filter(item => item.type === 'scope-of-work') ||
      []) {
      let divStr = '';
      divStr += `<input type="checkbox" ${
        row.checked ? 'checked' : ''
      } style="height: 16px; width: 16px; margin: 0px; accent-color: #1a79a1; pointer-events: none;"></input>`;
      divStr += `<span>${counter++}. </span>`;

      let value = '';
      for (const item of row.list) {
        if (item.type === 'string' || item.type === 'input-text') {
          if (!_.isEmpty(item.value)) {
            value += `<span>${item.value}&nbsp;</span>`;
          } else {
            value += `<span>_________________&nbsp;</span>`;
          }
        }
        if (item.type === 'input-checkbox') {
          value += `<input type="checkbox" style="height: 16px; width: 16px; margin: 0px; accent-color: #1a79a1; pointer-events: none;" ${
            item.value ? 'checked' : ''
          }></input>&nbsp;`;
        }
      }

      divStr += `<span>${value}</span>`;

      result += divStr;
    }

    return result;
  },

  'Contract Description': (self, isNew) => {
    let checklist = [];
    if (isNew) {
      checklist = CONTRACT_CHECKLIST;
    } else {
      checklist = self.contract ? self.contract.contractChecklist || [] : [];
    }

    let result = '';
    for (const row of checklist.filter(item => item.type === 'description') ||
      []) {
      let divStr = '';

      let value = '';
      for (const item of row.list) {
        if (item.type === 'input-text') {
          if (!_.isEmpty(item.value)) {
            value += `<span>&nbsp;${item.value}&nbsp;</span>`;
          } else {
            value += `<span>______________________________________________________________ __________________________________________________________________________________________________________________________________&nbsp;</span>`;
          }
        }
      }

      divStr += `<span>${value}</span>`;

      result += divStr;
    }

    return result;
  },

  'Total Sum Payment': (self, isNew) => {
    let checklist = [];
    if (isNew) {
      checklist = CONTRACT_CHECKLIST;
    } else {
      checklist = self.contract ? self.contract.contractChecklist || [] : [];
    }

    let result = '';
    for (const row of checklist.filter(
      item => item.type === 'total-sum-payment'
    ) || []) {
      let divStr = '';

      let value = '';
      for (const item of row.list) {
        if (item.type === 'input-text') {
          if (!_.isEmpty(item.value)) {
            value += `<span>&nbsp;${item.value}&nbsp;</span>`;
          } else {
            value += `<span>_____________________&nbsp;</span>`;
          }
        }
      }

      divStr += `<span>${value}</span>`;

      result += divStr;
    }

    return result;
  },

  'Init Payment A': (self, isNew) => {
    let checklist = [];
    if (isNew) {
      checklist = CONTRACT_CHECKLIST;
    } else {
      checklist = self.contract ? self.contract.contractChecklist || [] : [];
    }

    let result = '';

    for (const row of checklist.filter(
      item => item.type === 'init-payment-a'
    ) || []) {
      for (const item of row.list) {
        let value = '';
        if (item.type === 'input-text') {
          if (!_.isEmpty(item.value) && row.id === 20) {
            value += `${moment(item.value).format('MM/DD/YYYY')} `;
          } else if (!_.isEmpty(item.value)) {
            value += `${item.value}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
          } else {
            value += '_________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
          }
        } else if (item.type === 'string') {
          if (!_.isEmpty(item.value)) {
            value += `${item.value}`;
          } else {
            value += '_________________&nbsp;';
          }
        }

        result += `${value}`;
      }
    }

    return `<span>${result}</span>`;
  },

  'Init Payment B': (self, isNew) => {
    let checklist = [];
    if (isNew) {
      checklist = CONTRACT_CHECKLIST;
    } else {
      checklist = self.contract ? self.contract.contractChecklist || [] : [];
    }
    let result = '';

    for (const row of checklist.filter(
      item => item.type === 'init-payment-b'
    ) || []) {
      let value = '';
      for (const item of row.list) {
        if (item.type === 'string' || item.type === 'input-text') {
          if (!_.isEmpty(item.value)) {
            value += `${item.value} `;
          } else {
            value += `_____________________&nbsp;`;
          }
        }
      }

      result += `${value}`;
    }

    return `<span>${result}</span>`;
  },

  'Init Payment C': (self, isNew) => {
    let checklist = [];
    if (isNew) {
      checklist = CONTRACT_CHECKLIST;
    } else {
      checklist = self.contract ? self.contract.contractChecklist || [] : [];
    }

    let result = '';
    for (const row of checklist.filter(
      item => item.type === 'init-payment-c'
    ) || []) {
      let value = '';
      for (const item of row.list) {
        if (item.type === 'string' && !_.isEmpty(item.value)) {
          value += `${item.value} `;
        } else if (item.type === 'input-text' && !_.isEmpty(item.value)) {
          value += `${moment(item.value).format(
            'MM/DD/YYYY'
          )}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        } else {
          value += `_____________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        }
      }

      result += `${value}`;
    }

    return `<span>${result}</span>`;
  },

  'Date Note': (self, isNew) => {
    let checklist = [];
    if (isNew) {
      checklist = CONTRACT_CHECKLIST;
    } else {
      checklist = self.contract ? self.contract.contractChecklist || [] : [];
    }

    let result = '';
    for (const row of checklist.filter(item => item.type === 'date-note') ||
      []) {
      let divStr = '';

      let value = '';
      for (const item of row.list) {
        if (item.type === 'input-text') {
          if (!_.isEmpty(item.value)) {
            value += `<span>&nbsp;${item.value}&nbsp;</span>`;
          } else {
            value += `<span>_____________________&nbsp;</span>`;
          }
        }
      }

      divStr += `<span>${value}</span>`;

      result += divStr;
    }

    return result;
  },
};

export const contractDataCalculator = {
  ...contractInfoDataCalculator,
  ...contractCheckListDataCalculator,
};
