export const numberRoundUp = number => {
  const minuteConst = 15 / 60;
  return Math.ceil(number / minuteConst) * minuteConst;
};

export function ptbh(a, b, c) {
  // ax2 + bx + c = 0
  if (a == 0) {
    if (b == 0) {
      if (c == 0) {
        return null;
      } else {
        return null;
      }
    } else {
      return -c / b;
    }
  } else {
    const dt = b * b - 4 * a * c;
    if (dt < 0) {
      return;
    } else if (dt == 0) {
      return -b / (2 * a);
    } else {
      const x1 = (-b + Math.sqrt(dt)) / (2 * a);
      const x2 = (-b - Math.sqrt(dt)) / (2 * a);
      return { x1, x2 };
    }
  }
}

export const roundNumber = (value, numberOfDecimalPlaces) => {
  const m = Number(
    (Math.abs(value) * Math.pow(10, numberOfDecimalPlaces)).toPrecision(15)
  );

  return (
    (Math.round(m) / Math.pow(10, numberOfDecimalPlaces)) * Math.sign(value)
  );
};

export const formatCurrency = val => {
  val = val.replace('$', '');
  val = val.replace(/,/g, '');

  if (!Number.isNaN(Number.parseFloat(val))) {
    val = '$' + Number.parseFloat(val).toLocaleString();
  } else {
    val = '$';
  }
  return val;
};

export const formatPriceNumber = (val, isShowDollar = true) => {
  val = val.replace('$', '');
  val = val.replace(/,/g, '');
  let r = 0;
  let active = 0;
  let isText = false;
  for (let i = 0; i < val.toString().length; i++) {
    if (val.toString()[i] === '.') {
      r++;
      if (r <= 1) {
        active = 1;
      } else {
        active = 2;
      }
    }
  }
  for (let j = 0; j < val.length; j++) {
    if (val[j].search(/[^0-9.]/g) == 0) {
      isText = true;
    }
  }
  if (!Number.isNaN(Number.parseFloat(val))) {
    if (active === 1) {
      let stringCurrency = val.toString().split('.');
      if (
        stringCurrency[1] !== undefined &&
        stringCurrency[1].length > 2 &&
        isText === false
      ) {
        let delTwoNumber = val
          .toString()
          .substring(0, val.toString().length - 1);
        val =
          `${isShowDollar ? '$' : ''}` +
          delTwoNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      if (stringCurrency[1] !== undefined && stringCurrency[1].length <= 2) {
        val =
          `${isShowDollar ? '$' : ''}` +
          val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    if (active === 2) {
      let delPoint = val.toString().substring(0, val.toString().length - 1);
      val =
        `${isShowDollar ? '$' : ''}` +
        delPoint.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else if (active === 0) {
      val =
        `${isShowDollar ? '$' : ''}` +
        val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (isText == true) {
      let delText = val.toString().substring(0, val.toString().length - 1);
      if (active === 1) {
        let stringCurrency = val.toString().split('.');
        val =
          `${isShowDollar ? '$' : ''}` +
          delText.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (stringCurrency[1] !== undefined && stringCurrency[1].length <= 2) {
          val = delText.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      } else {
        val = delText.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
  } else {
    val = '';
  }
  return val;
};
export const formatDots = val => {
  const arr = val.split('.');
  if (!arr[arr.length - 1]) {
    val = val.substring(0, val.length - 1);
  }
  return parseFloat(
    formatPriceNumber(val).replaceAll('$', '').replaceAll(',', '')
  );
};
export const formatFloatNumber = val => {
  val = val.replace(/,/g, '');

  if (!Number.isNaN(Number.parseFloat(val))) {
    val = Number.parseFloat(val).toLocaleString();
  }

  return val;
};
