import moment from 'moment';
import { firebase } from '../services/firebase.service';

export const nowInHumanReadable = () => {
  return moment().format('MMDDYYYY_hhmmss');
};

const dateToValue = a => {
  const seconds = a.seconds || a._seconds || 0;
  const nanoseconds = a.nanoseconds || a._nanoseconds || 0;

  return seconds * 1000 + nanoseconds / 1000000;
};

const sortByCreated = (list, isDesc) => {
  if (!list.length) return [];
  return list.slice().sort((a, b) => {
    const aCreatedAt = a.createdAt || { nanoseconds: 0, seconds: 0 };
    const bCreatedAt = b.createdAt || { nanoseconds: 0, seconds: 0 };
    return (
      (dateToValue(aCreatedAt) - dateToValue(bCreatedAt)) * (isDesc ? -1 : 1)
    );
  });
};

export const sortBy = (list, propName, isDesc) => {
  if (!list.length) return [];
  return list.slice().sort((a, b) => {
    const aDateAt = a[propName] || { nanoseconds: 0, seconds: 0 };
    const bDateAt = b[propName] || { nanoseconds: 0, seconds: 0 };
    return (dateToValue(aDateAt) - dateToValue(bDateAt)) * (isDesc ? -1 : 1);
  });
};

export const sortLatest = list => sortByCreated(list, true);

export const sortEarliest = list => sortByCreated(list, false);

export const tomorrow = () => {
  const currentDate = new Date();
  const tomorrow = currentDate.setDate(currentDate.getDate() + 1);
  return new Date(tomorrow);
};

export const getTomorrow = date => {
  const tomorrowDay = new Date(date).getDate() + 1;
  const tomorrow = new Date(date);
  tomorrow.setDate(tomorrowDay);
  return tomorrow;
};

export const startDateOf90DaysAgo = () => {
  const currentDate = new Date();
  const fromDate = currentDate.setDate(currentDate.getDate() - 90);
  return new Date(fromDate);
};

export const lastWeekMonday = () => {
  const today = moment();
  const lastWeekStart = today.clone().subtract(7, 'days').startOf('isoWeek');
  const lastWeekMonday = lastWeekStart.clone().add(0, 'days');
  const newDate = lastWeekMonday._d;
  return newDate;
};

export const lastWeekSunday = () => {
  const today = moment();
  const lastWeekStart = today.clone().subtract(7, 'days').startOf('isoWeek');
  const lastWeekSunday = lastWeekStart.clone().add(6, 'days');
  const newDate = lastWeekSunday._d;
  return newDate;
};

export const convertDateTimeFromAlgoliaToDate = dateTime => {
  const dateToValue =
    dateTime._seconds * 1000 + dateTime._nanoseconds / 1000000;
  return new Date(dateToValue);
};

export const sortByUpdatedOrCreated = (list, isDesc) => {
  if (!list.length) return [];
  return list.slice().sort((a, b) => {
    const aDate =
      a.updatedAt === null
        ? firebase.firestore.Timestamp.now()
        : a.updatedAt || a.createdAt || { nanoseconds: 0, seconds: 0 };
    const bDate =
      b.updatedAt === null
        ? firebase.firestore.Timestamp.now()
        : b.updatedAt || b.createdAt || { nanoseconds: 0, seconds: 0 };
    return (dateToValue(aDate) - dateToValue(bDate)) * (isDesc ? -1 : 1);
  });
};

export const convertTimestampToDate = dateTime => {
  const seconds = dateTime.seconds || dateTime._seconds;
  const nanoseconds = dateTime.nanoseconds || dateTime._nanoseconds || 0;

  if (typeof seconds !== 'undefined' && typeof nanoseconds !== 'undefined') {
    const dateToValue = seconds * 1000 + nanoseconds / 1000000;
    return new Date(dateToValue);
  }
};

export const getWeekDates = date => {
  const currentDate = date.toDate();
  const dayOfWeek = currentDate.getDay();
  const diffToMonday = (dayOfWeek + 6) % 7;
  const diffToSunday = 6 - dayOfWeek;

  const monday = new Date(currentDate);
  monday.setDate(currentDate.getDate() - diffToMonday);

  const sunday = new Date(currentDate);
  sunday.setDate(currentDate.getDate() + diffToSunday);

  const formatDate = date => {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return {
    startDate: formatDate(monday),
    endDate: formatDate(sunday),
  };
};
