import _ from 'lodash';

const getFullAddress = address => {
  if (_.isEmpty(address)) return '';
  return `${address.address ? address.address : ''}${
    address.city ? ', ' + address.city : ''
  }${address.state ? ', ' + address.state : ''}${
    address.zipcode ? ' ' + address.zipcode : ''
  }${address.country ? ', ' + address.country : ''} `;
};

const getMainAddress = addresses => {
  let address = {};
  if (!_.isEmpty(addresses) && _.isArray(addresses)) {
    address = addresses.find(item => item.code === 'main');
    return address || addresses[0] || {};
  }

  return {};
};

export { getFullAddress, getMainAddress };
