// proposal
export const PROPOSAL_STATUS = Object.freeze({
  ACTIVE: { id: 'active', name: 'Active' },
  INACTIVE: { id: 'inactive', name: 'Inactive' },
  SENT: { id: 'sent', name: 'Sent' },
  VIEWED: { id: 'viewed', name: 'Viewed' },
  WITHDRAWN: { id: 'withdraw', name: 'Withdrawn' },
  SIGNED: { id: 'signed', name: 'Signed' },
  DRAFT: { id: 'draft', name: 'Draft' },

  getStatusName(status) {
    const statuses = [
      this.ACTIVE,
      this.INACTIVE,
      this.SENT,
      this.VIEWED,
      this.WITHDRAWN,
      this.SIGNED,
      this.DRAFT,
    ];
    const resultFindStatus = statuses.find(s => s.id === status);
    return resultFindStatus ? resultFindStatus.name : this.DRAFT.name;
  },
});
